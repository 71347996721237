import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import type {
  MetaFunction,
  LoaderFunctionArgs,
  HeadersFunction,
} from 'react-router';
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
  useRouteError,
  data,
} from 'react-router';
import { useEffect } from 'react';
import crypto from 'crypto';
import { getToast, ToastMessage } from 'remix-toast';
import 'modern-css-reset/dist/reset.min.css';
import '~/styles/root.css';
import '~/styles/layout.css';
import '~/styles/components/button.css';
import '~/styles/components/popover.css';
import '~/styles/components/forms.css';
import '~/styles/components/type.css';
import '~/styles/components/alert.css';
import { optimizelyIdCookie } from '~/cookies.server';
import { Layout } from '~/components/layout';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import { OPTIMIZELY_KEY } from './utils/constants';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import { getUser } from '~/auth.server';
import type { Email, User } from '~/models/user.server';
import { getUserTeams, type Team } from '~/models/team.server';
import { userIsPro, getUserEmails } from '~/models/user.server';
import parseCacheControl from 'parse-cache-control';
import InvisibleRecaptcha from './components/shared/InvisibleRecaptcha';
import { ClientOnly } from 'remix-utils/client-only';
import Toast from './components/shared/Toast';
import { ToastProvider, useToast } from './context/toast-context';

export const meta: MetaFunction = () => [
  {
    title: 'Glitch: The friendly community where everyone builds the web',
  },
  {
    property: 'og:title',
    content: 'Glitch: The friendly community where everyone builds the web',
  },
  {
    name: 'description',
    content: 'Simple, powerful, free tools to create and use millions of apps.',
  },
  {
    property: 'og:description',
    content: 'Simple, powerful, free tools to create and use millions of apps.',
  },
  {
    property: 'og:image',
    content:
      'https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/home-ogimage.png',
  },
];

export function links() {
  return [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    { rel: 'manifest', href: '/site.webmanifest' },
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#ff00bc' },
  ];
}

export type LoaderData = {
  emails: Email[];
  loggedInUser?: User;
  optimizelyId: string;
  teams?: Team[];
  userIsPro: boolean;
  toast?: ToastMessage;
};

const optimizelyClient = createInstance({
  sdkKey: OPTIMIZELY_KEY,
  odpOptions: { disabled: true },
});

// Temporary to prevent Fastly from caching API responses, until we figure out how to do it properly
export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const loaderCache = parseCacheControl(loaderHeaders.get('Cache-Control'));

  if (loaderCache) {
    // Use what we're sending from the loader, but override the max-age
    return {
      'Cache-Control': `max-age=${loaderCache['max-age']}`,
    };
  }

  return {
    'Cache-Control': 'private',
  };
};

export async function loader({ request }: LoaderFunctionArgs) {
  const { toast, headers: toastHeaders } = await getToast(request);
  const loggedInUser = await getUser(request);
  const teams = await getUserTeams(loggedInUser?.id, request);
  const isPro = await userIsPro(!!loggedInUser, request);
  const emails = await getUserEmails(loggedInUser?.id, request);

  const cookieHeader = request.headers.get('Cookie');
  const optimizelyCookie = (await optimizelyIdCookie.parse(cookieHeader)) || {};
  const optimizelyId =
    loggedInUser?.id || optimizelyCookie?.id || crypto.randomUUID();
  optimizelyCookie.id = optimizelyId;

  // Remove persistentToken from the loggedInUser object so it doesn't get sent to the client
  const sanitizedUser = { ...loggedInUser };
  delete sanitizedUser.persistentToken;

  const headers = new Headers(toastHeaders);
  headers.append(
    'Set-Cookie',
    await optimizelyIdCookie.serialize(optimizelyCookie),
  );

  return data(
    {
      loggedInUser: sanitizedUser.id ? sanitizedUser : null,
      optimizelyId,
      teams: teams,
      userIsPro: isPro,
      emails,
      toast,
    },
    {
      headers,
    },
  );
}

const PendoScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(apiKey){
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
          v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
              o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
              y=e.createElement(n);y.async=!0;y.src='https://content.product.glitch.com/agent/static/'+apiKey+'/pendo.js';
              z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
      })('211866e8-df03-4f24-7359-b49dd6253c1e');
    `;
    document.body.appendChild(script);
  }, []);

  return null;
};

export function ErrorBoundary() {
  const error = useRouteError();

  const errorInfo = () => {
    if (isRouteErrorResponse(error)) {
      return (
        <>
          <h1 className="header-1">
            {error.status} {`You've Found A Glitch`}
          </h1>
          <p className="paragraph">
            You’re on the development preview version of the Glitch website, so
            “not found” means we probably haven’t built this page yet. We’re in
            the process of rebuilding Glitch from the ground up. This preview
            site is our ongoing commitment to doing that work alongside the
            Glitch community.
          </p>
          <p className="paragraph">
            We’ve set up{' '}
            <Link
              className="link"
              target="_blank"
              to="https://support.glitch.com/t/sneak-peek-glitch-development-preview/64190"
              rel="noreferrer"
            >
              a thread in the forum
            </Link>{' '}
            so you can send feedback and tell us what you think.
          </p>
        </>
      );
    } else if (error instanceof Error) {
      return (
        <>
          <h1 className="header-1">Error</h1>
          <p>{error.message}</p>
          <p>The stack trace is:</p>
          <pre>{error.stack}</pre>
        </>
      );
    } else {
      return <h1 className="header-1">Unknown Error</h1>;
    }
  };

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>Glitch - Oops!</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <PendoScript />
      </head>
      <body>
        <Layout>
          <div className="page-content-wrapper medium">
            <section className="section">{errorInfo()}</section>
          </div>
        </Layout>
      </body>
    </html>
  );
}

function App() {
  const { loggedInUser, teams, optimizelyId, userIsPro, emails, toast } =
    useLoaderData() as LoaderData;
  const navigation = useNavigation();
  const { setToastTitle, setToastOpen } = useToast();

  useEffect(() => {
    if (navigation.state === 'loading' || navigation.state === 'submitting') {
      Nprogress.start();
    } else {
      Nprogress.done();
    }
  }, [navigation.state]);

  useEffect(() => {
    // @ts-expect-error-next-line TS doesn't know about pendo
    if (!window || !pendo) return;
    if (loggedInUser) {
      // @ts-expect-error-next-line TS doesn't know about pendo
      pendo.initialize({
        visitor: {
          id: loggedInUser.id,
          email: emails.find(({ primary }) => primary)?.email,
          hasGlitchPro: userIsPro,
          teamCount: teams?.length || 0,
          // Leaving out project count for now because the only way
          // To get all of the projects is to request all of the projects
          // Which could be slow for users, and I don't want to do that on the page root
          // projectCount: projects.length
          username: loggedInUser.login,
        },
        account: {
          id: loggedInUser.id,
        },
      });
    }
    if (!loggedInUser) {
      // Pendo creates a temporary ID for the user, and will merge with their account if they sign in
      // @ts-expect-error-next-line TS doesn't know about pendo
      pendo.initialize();
    }
  }, [emails, loggedInUser, teams?.length, userIsPro]);

  useEffect(() => {
    if (toast) {
      setToastTitle(toast.message);
      setToastOpen(true);
    }
  }, [toast]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <PendoScript />
      </head>
      <body>
        <OptimizelyProvider
          optimizely={optimizelyClient}
          user={{
            id: `${optimizelyId}`,
            attributes: {
              userId: `${loggedInUser?.id}` || null,
              hasGlitchPro: userIsPro,
            },
          }}
        >
          <Layout user={loggedInUser} teams={teams}>
            <Outlet />
            <ScrollRestoration />
            <Scripts />
            <Toast />
            {!loggedInUser && (
              <ClientOnly>{() => <InvisibleRecaptcha />}</ClientOnly>
            )}
          </Layout>
        </OptimizelyProvider>
      </body>
    </html>
  );
}

const AppWithContext = () => (
  <ToastProvider>
    <App />
  </ToastProvider>
);

export default withSentry(AppWithContext);
